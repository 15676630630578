@mixin loading-animate() {
    background-image: linear-gradient(90deg, color($grey, 300) 25%, color($grey, 400) 37%, color($grey, 300) 63%);
    animation: loading 1.4s ease infinite;
    background-size: 400% 100%;
}

@keyframes loading {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}
