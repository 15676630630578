@import '../../shared/styles/constants';

@mixin small-card-properties {
    box-shadow: none;

    .listings-action-menu-container,
    .locale-section,
    .listings-seller-details-component.desktop-device.agency .agency-data .established,
    .employees-big-list-container,
    .listings-seller-details-component.agency .agency-data,
    .card-footer {
        display: none;
    }

    .job-title-small {
        display: block;
    }
}

.listings-expert-card-container {
    // sass-lint:disable no-misspelled-properties
    container-type: inline-size;
    min-width: 200px;

    .expert-card-body {
        flex-grow: 1;

        &.mobile-view {
            margin-top: 10px;
        }
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    border: 1px solid color($grey, 400);
    border-radius: $border_radius_lg;
    position: relative;

    &.shadow-on-hover:hover {
        box-shadow: $box_shadow_z2;
    }

    .clickable-section {
        cursor: pointer;

        li.text-item,
        div > span,
        ul > span,
        p,
        b,
        ul > h6 {
            cursor: auto;
        }
    }

    @container (max-width: #{$extra_small_card_threshold}) {
        padding: 16px;
    }

    @container (min-width: #{$small_card_threshold + 1}) {
        .job-title-small,
        .employees-short-list-container,
        .card-footer-small {
            display: none;
        }
    }

    @container (max-width: #{$small_card_threshold}) {
        @include small-card-properties;
    }

    &.small-card {
        @include small-card-properties;
    }
}
