@mixin rows-ellipsis($line_height, $lines: 2) {
    height: $lines * $line_height;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;
    word-break: break-word;

    @supports (-webkit-line-clamp: $lines) {
        display: box !important;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
    }
}

@mixin bullet {
    content: '\2022';
    color: color($grey, 700);
    font-size: 16px;
    margin: 0 8px;
}

@mixin loading-animate {
    background-image: linear-gradient(90deg, color($grey, 300) 25%, color($grey, 400) 37%, color($grey, 300) 63%);
    animation: loading 1.4s ease infinite;
    background-size: 400% 100%;
}

@keyframes loading {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

@mixin fade-in-slide-animate {
    animation: fade-in-slide 0.25s ease-in-out;
}

@mixin fade-out-slide-animate {
    animation: fade-out-slide 0.25s linear;
}

@mixin fade-in-animate {
    animation: fade-in 0.25s ease-in-out;
}

@mixin fade-out-animate {
    animation: fade-out 0.25s linear;
}

@keyframes fade-in-slide {
    0% {
        transform: translate3d(0, 16px, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes fade-out-slide {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, 16px, 0);
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
