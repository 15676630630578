$filter_wrapper_width: 275px;
$page_side_padding: 32px;
$box_shadow: 0 12px 18px rgba(0, 0, 0, 0.14);
$page_max_width_xl: 1240px;
$non_sticky_filter_margin_top: 24px;
$sticky_filter_margin_top: 16px;

.layout-row {
    margin-right: auto;
    margin-left: auto;
    padding: 0 10px;
    max-width: 1400px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    flex: 0 1 auto;

    @include media-query-grid(sm) {
        padding: 0 15px;
    }
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-wrap {
    flex-wrap: wrap;
}
