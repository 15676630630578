/* sass-lint:disable no-color-literals */

@use 'sass:map';
@use 'sass:list';

$primary_1200: #0B3A23;
$primary_1100: #0F5533;
$primary_1000: #126F43;
$primary_900: #188652;
$primary_800: #19A463;
$primary_700: #1DBF73;
$primary_600: #3FCA89;
$primary_500: #62D49F;
$primary_400: #84DFB5;
$primary_300: #A6EACA;
$primary_200: #C9F4E0;
$primary_100: #E8FAF4;
$business_1200: #0D084D;
$business_1100: #1E1692;
$business_1000: #2E25AD;
$business_900: #2E25AD;
$business_800: #2E25AD;
$business_700: #584AFF;
$business_600: #584AFF;
$business_500: #B1ABFF;
$business_400: #B1ABFF;
$business_300: #B1ABFF;
$business_200: #E4E3FF;
$business_100: #F6F6FF;
$pro_1200: #026A5D;
$pro_1100: #027C6C;
$pro_1000: #028D7B;
$pro_900: #029F8B;
$pro_800: #02B09A;
$pro_700: #02C2A9;
$pro_600: #28CCB7;
$pro_500: #4DD6C5;
$pro_400: #73E1D3;
$pro_300: #99EBE0;
$pro_200: #BEF5EE;
$pro_100: #E8FFFC;
$pink_1200: #642744;
$pink_1100: #833359;
$pink_1000: #A23F6E;
$pink_900: #C14A83;
$pink_800: #E05698;
$pink_700: #FF62AD;
$pink_600: #FF7AB9;
$pink_500: #FF91C6;
$pink_400: #FFA9D2;
$pink_300: #FFC0DE;
$pink_200: #FFD7EB;
$pink_100: #FFEFF7;
$teal_1200: #065A60;
$teal_1100: #10727B;
$teal_1000: #1B8996;
$teal_900: #25A1B0;
$teal_800: #30B8CB;
$teal_700: #3AD0E6;
$teal_600: #57D7EA;
$teal_500: #75DFEE;
$teal_400: #92E6F3;
$teal_300: #AFEDF7;
$teal_200: #CDF5FB;
$teal_100: #ECFCFF;
$lime_1200: #254200;
$lime_1100: #465A00;
$lime_1000: #687200;
$lime_900: #7D8900;
$lime_800: #BBCE00;
$lime_700: #D0E500;
$lime_600: #DAEA3D;
$lime_500: #E3EE74;
$lime_400: #E8F094;
$lime_300: #EDF2B3;
$lime_200: #F1F4CB;
$lime_100: #F8F9E5;
$yellow_1200: #825723;
$yellow_1100: #9B6928;
$yellow_1000: #B47C2E;
$yellow_900: #CD8E33;
$yellow_800: #E6A139;
$yellow_700: #FFB33E;
$yellow_600: #FFBE5B;
$yellow_500: #FFCA79;
$yellow_400: #FFD596;
$yellow_300: #FFE0B3;
$yellow_200: #FFECD1;
$yellow_100: #FFF7EE;
$orange_1200: #804317;
$orange_1100: #99501B;
$orange_1000: #B25D1F;
$orange_900: #CA6923;
$orange_800: #E37627;
$orange_700: #FC832B;
$orange_600: #FD964B;
$orange_500: #FDA86A;
$orange_400: #FEBB8A;
$orange_300: #FECDAA;
$orange_200: #FEDFC9;
$orange_100: #FFF2E9;
$red_1200: #782020;
$red_1100: #912626;
$red_1000: #AB2D2D;
$red_900: #C43333;
$red_800: #D93C3C;
$red_700: #F74040;
$red_600: #F85D5D;
$red_500: #FA7A7A;
$red_400: #FB9797;
$red_300: #FCB3B3;
$red_200: #FED0D0;
$red_100: #FFEDED;
$purple_1200: #462072;
$purple_1100: #56288A;
$purple_1000: #6731A1;
$purple_900: #7739B9;
$purple_800: #8842D0;
$purple_700: #984AE8;
$purple_600: #A866EC;
$purple_500: #B881F0;
$purple_400: #C89DF3;
$purple_300: #D8B9F7;
$purple_200: #E8D4FB;
$purple_100: #F8F0FF;
$blue_1200: #122349;
$blue_1100: #1D3369;
$blue_1000: #284389;
$blue_900: #3453A8;
$blue_800: #3F63C8;
$blue_700: #446EE7;
$blue_600: #6689EC;
$blue_500: #819EF0;
$blue_400: #9DB4F3;
$blue_300: #B9C9F7;
$blue_200: #D4DEFB;
$blue_100: #F0F4FF;
$green_1200: #0B3A23;
$green_1100: #0F5533;
$green_1000: #126F43;
$green_900: #188652;
$green_800: #19A463;
$green_700: #1DBF73;
$green_600: #3FCA89;
$green_500: #62D49F;
$green_400: #84DFB5;
$green_300: #A6EACA;
$green_200: #C9F4E0;
$green_100: #E8FAF4;
$grey_1200: #222325;
$grey_1100: #404145;
$grey_1000: #62646A;
$grey_900: #74767E;
$grey_800: #95979D;
$grey_700: #B5B6BA;
$grey_600: #C5C6C9;
$grey_500: #DADBDD;
$grey_400: #E4E5E7;
$grey_300: #EFEFF0;
$grey_200: #F5F5F5;
$grey_100: #FAFAFA;
$fiverr_error: #F74040;
$fiverr_success: #19A463;
$fiverr_green: #1DBF73;
$black: #000;
$white: #fff;

$primary: 'primary';
$business: 'business';
$pro: 'pro';
$pink: 'pink';
$teal: 'teal';
$lime: 'lime';
$yellow: 'yellow';
$orange: 'orange';
$red: 'red';
$purple: 'purple';
$blue: 'blue';
$green: 'green';
$grey: 'grey';

$drop_shadow_map: (
    aa: drop-shadow(0 0 .880517px rgba(0, 0, 0, .0288)) drop-shadow(0 0 2.29197px rgba(0, 0, 0, .03)) drop-shadow(0 0 5.52px rgba(0, 0, 0, .0543)) drop-shadow(0 0 11px rgba(0, 0, 0, .09)),
    z1: drop-shadow(0 1.2px 1.92251px rgba(0, 0, 0, .02)) drop-shadow(0 2px 3.68135px rgba(0, 0, 0, .04)) drop-shadow(0 1px 5px rgba(0, 0, 0, .05)),
    z2: drop-shadow(0 .14px 2.29266px rgba(0, 0, 0, .0323114)) drop-shadow(0 .37px 4.42626px rgba(0, 0, 0, .0476886)) drop-shadow(0 3px 7px rgba(0, 0, 0, .09)),
    z3: drop-shadow(0 .08px 2.55728px rgba(0, 0, 0, .0525061)) drop-shadow(0 1.28px 5.57858px rgba(0, 0, 0, .0774939)) drop-shadow(0 3px 10px rgba(0, 0, 0, .13)),
    z4: drop-shadow(0 .266004px 1.13052px rgba(0, 0, 0, .0686618)) drop-shadow(0 .893452px 3.79717px rgba(0, 0, 0, .101338)) drop-shadow(0 5px 17px rgba(0, 0, 0, .17)),
    z5: drop-shadow(0 0 2.17382px rgba(0, 0, 0, .0487776)) drop-shadow(0 1.75px 6.01034px rgba(0, 0, 0, .07)) drop-shadow(0 3.63px 14.4706px rgba(0, 0, 0, .0912224)) drop-shadow(0 22px 48px rgba(0, 0, 0, .14))
);
$drop_shadow_z5: drop-shadow(0 0 2.17382px rgba(0, 0, 0, .0487776)) drop-shadow(0 1.75px 6.01034px rgba(0, 0, 0, .07)) drop-shadow(0 3.63px 14.4706px rgba(0, 0, 0, .0912224)) drop-shadow(0 22px 48px rgba(0, 0, 0, .14));
$drop_shadow_z4: drop-shadow(0 .266004px 1.13052px rgba(0, 0, 0, .0686618)) drop-shadow(0 .893452px 3.79717px rgba(0, 0, 0, .101338)) drop-shadow(0 5px 17px rgba(0, 0, 0, .17));
$drop_shadow_z3: drop-shadow(0 .08px 2.55728px rgba(0, 0, 0, .0525061)) drop-shadow(0 1.28px 5.57858px rgba(0, 0, 0, .0774939)) drop-shadow(0 3px 10px rgba(0, 0, 0, .13));
$drop_shadow_z2: drop-shadow(0 .14px 2.29266px rgba(0, 0, 0, .0323114)) drop-shadow(0 .37px 4.42626px rgba(0, 0, 0, .0476886)) drop-shadow(0 3px 7px rgba(0, 0, 0, .09));
$drop_shadow_z1: drop-shadow(0 1.2px 1.92251px rgba(0, 0, 0, .02)) drop-shadow(0 2px 3.68135px rgba(0, 0, 0, .04)) drop-shadow(0 1px 5px rgba(0, 0, 0, .05));
$drop_shadow_aa: drop-shadow(0 0 .880517px rgba(0, 0, 0, .0288)) drop-shadow(0 0 2.29197px rgba(0, 0, 0, .03)) drop-shadow(0 0 5.52px rgba(0, 0, 0, .0543)) drop-shadow(0 0 11px rgba(0, 0, 0, .09));

$box_shadow_map: (
    aa: (0 0 .880517px rgba(0, 0, 0, .0288), 0 0 2.29197px rgba(0, 0, 0, .03), 0 0 5.52px rgba(0, 0, 0, .0543), 0 0 11px rgba(0, 0, 0, .09)),
    z1: (0 1.2px 1.92251px rgba(0, 0, 0, .02), 0 2px 3.68135px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, .05)),
    z2: (0 .14px 2.29266px rgba(0, 0, 0, .0323114), 0 .37px 4.42626px rgba(0, 0, 0, .0476886), 0 3px 7px rgba(0, 0, 0, .09)),
    z3: (0 .08px 2.55728px rgba(0, 0, 0, .0525061), 0 1.28px 5.57858px rgba(0, 0, 0, .0774939), 0 3px 10px rgba(0, 0, 0, .13)),
    z4: (0 .266004px 1.13052px rgba(0, 0, 0, .0686618), 0 .893452px 3.79717px rgba(0, 0, 0, .101338), 0 5px 17px rgba(0, 0, 0, .17)),
    z5: (0 0 2.17382px rgba(0, 0, 0, .0487776), 0 1.75px 6.01034px rgba(0, 0, 0, .07), 0 3.63px 14.4706px rgba(0, 0, 0, .0912224), 0 22px 48px rgba(0, 0, 0, .14))
);
$box_shadow_z5: (0 0 2.17382px rgba(0, 0, 0, .0487776), 0 1.75px 6.01034px rgba(0, 0, 0, .07), 0 3.63px 14.4706px rgba(0, 0, 0, .0912224), 0 22px 48px rgba(0, 0, 0, .14));
$box_shadow_z4: (0 .266004px 1.13052px rgba(0, 0, 0, .0686618), 0 .893452px 3.79717px rgba(0, 0, 0, .101338), 0 5px 17px rgba(0, 0, 0, .17));
$box_shadow_z3: (0 .08px 2.55728px rgba(0, 0, 0, .0525061), 0 1.28px 5.57858px rgba(0, 0, 0, .0774939), 0 3px 10px rgba(0, 0, 0, .13));
$box_shadow_z2: (0 .14px 2.29266px rgba(0, 0, 0, .0323114), 0 .37px 4.42626px rgba(0, 0, 0, .0476886), 0 3px 7px rgba(0, 0, 0, .09));
$box_shadow_z1: (0 1.2px 1.92251px rgba(0, 0, 0, .02), 0 2px 3.68135px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, .05));
$box_shadow_aa: (0 0 .880517px rgba(0, 0, 0, .0288), 0 0 2.29197px rgba(0, 0, 0, .03), 0 0 5.52px rgba(0, 0, 0, .0543), 0 0 11px rgba(0, 0, 0, .09));

$heading_sizes: (
    2xl: text-display-1,
    xl: text-display-2,
    lg: text-display-3,
    md: text-display-4,
    sm: text-display-5,
    xs: text-display-6
);
$heading_size_xs: text-display-6;
$heading_size_sm: text-display-5;
$heading_size_md: text-display-4;
$heading_size_lg: text-display-3;
$heading_size_xl: text-display-2;
$heading_size_2xl: text-display-1;

$text_sizes: (
    2xl: tbody-2,
    xl: tbody-3,
    lg: tbody-4,
    md: tbody-5,
    default: tbody-5,
    sm: tbody-6,
    xs: tbody-7
);
$text_size_xs: tbody-7;
$text_size_sm: tbody-6;
$text_size_default: tbody-5;
$text_size_md: tbody-5;
$text_size_lg: tbody-4;
$text_size_xl: tbody-3;
$text_size_2xl: tbody-2;

$sizes: (
    1_2: 50%,
    1_3: 33.33%,
    2_3: 66.66%,
    1_4: 25%,
    2_4: 50%,
    3_4: 75%
);
$size_3_4: 75%;
$size_2_4: 50%;
$size_1_4: 25%;
$size_2_3: 66.66%;
$size_1_3: 33.33%;
$size_1_2: 50%;

$spacings: (
    1: 4px,
    2: 8px,
    3: 12px,
    4: 16px,
    5: 20px,
    6: 24px,
    7: 28px,
    8: 32px,
    9: 36px,
    10: 40px,
    12: 48px,
    14: 56px,
    16: 64px,
    20: 80px,
    24: 96px,
    28: 112px,
    32: 128px,
    36: 144px,
    40: 160px,
    px: 1px,
    _5: 2px,
    1_5: 6px,
    2_5: 10px
);
$spacing_2_5: 10px;
$spacing_1_5: 6px;
$spacing__5: 2px;
$spacing_px: 1px;
$spacing_40: 160px;
$spacing_36: 144px;
$spacing_32: 128px;
$spacing_28: 112px;
$spacing_24: 96px;
$spacing_20: 80px;
$spacing_16: 64px;
$spacing_14: 56px;
$spacing_12: 48px;
$spacing_10: 40px;
$spacing_9: 36px;
$spacing_8: 32px;
$spacing_7: 28px;
$spacing_6: 24px;
$spacing_5: 20px;
$spacing_4: 16px;
$spacing_3: 12px;
$spacing_2: 8px;
$spacing_1: 4px;

$border_radiuses: (
    none: 0,
    sm: 4px,
    md: 6px,
    lg: 8px,
    xl: 12px,
    circle: 9999px
);
$border_radius_circle: 9999px;
$border_radius_xl: 12px;
$border_radius_lg: 8px;
$border_radius_md: 6px;
$border_radius_sm: 4px;
$border_radius_none: 0;

$font_serif: Georgia, 'Times New Roman', serif;
$font_accent: 'Macan', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font_domaine: 'DomaineDisplay', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font_primary: 'Macan', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$max_width: 1400px;

$breakpoint_reverse_sizes: (
    xs: 319px,
    sm: 599px,
    md: 899px,
    lg: 1159px,
    xl: 1759px
);
$breakpoint_reverse_xl: 1759px;
$breakpoint_reverse_lg: 1159px;
$breakpoint_reverse_md: 899px;
$breakpoint_reverse_sm: 599px;
$breakpoint_reverse_xs: 319px;

$breakpoint_sizes: (
    xs: 320px,
    sm: 600px,
    md: 900px,
    lg: 1160px,
    xl: 1760px
);
$breakpoint_xl: 1760px;
$breakpoint_lg: 1160px;
$breakpoint_md: 900px;
$breakpoint_sm: 600px;
$breakpoint_xs: 320px;

$animation_timing_functions: (
    standard: cubic-bezier(.75, 0, .25, 1),
    entrance: cubic-bezier(0, .7, 0, 1),
    exit: cubic-bezier(0, 0, 1, .3)
);
$animation_timing_function_exit: cubic-bezier(0, 0, 1, .3);
$animation_timing_function_entrance: cubic-bezier(0, .7, 0, 1);
$animation_timing_function_standard: cubic-bezier(.75, 0, .25, 1);
$animation_duration_slows: (
    01: 370ms,
    02: 400ms,
    03: 500ms
);
$animation_duration_slow_03: 500ms;
$animation_duration_slow_02: 400ms;
$animation_duration_slow_01: 370ms;
$animation_duration_moderates: (
    01: 200ms,
    02: 250ms,
    03: 300ms
);
$animation_duration_moderate_03: 300ms;
$animation_duration_moderate_02: 250ms;
$animation_duration_moderate_01: 200ms;
$animation_duration_fasts: (
    01: 70ms,
    02: 100ms,
    03: 120ms
);
$animation_duration_fast_03: 120ms;
$animation_duration_fast_02: 100ms;
$animation_duration_fast_01: 70ms;

$color_weights: 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200;

$color_names: 'grey', 'green', 'blue', 'purple', 'red', 'orange', 'yellow', 'lime', 'teal', 'pink', 'pro', 'business', 'primary';

$colors: (
    'grey': (
        100: #FAFAFA,
        200: #F5F5F5,
        300: #EFEFF0,
        400: #E4E5E7,
        500: #DADBDD,
        600: #C5C6C9,
        700: #B5B6BA,
        800: #95979D,
        900: #74767E,
        1000: #62646A,
        1100: #404145,
        1200: #222325
    ),
    'green': (
        100: #E8FAF4,
        200: #C9F4E0,
        300: #A6EACA,
        400: #84DFB5,
        500: #62D49F,
        600: #3FCA89,
        700: #1DBF73,
        800: #19A463,
        900: #188652,
        1000: #126F43,
        1100: #0F5533,
        1200: #0B3A23
    ),
    'blue': (
        100: #F0F4FF,
        200: #D4DEFB,
        300: #B9C9F7,
        400: #9DB4F3,
        500: #819EF0,
        600: #6689EC,
        700: #446EE7,
        800: #3F63C8,
        900: #3453A8,
        1000: #284389,
        1100: #1D3369,
        1200: #122349
    ),
    'purple': (
        100: #F8F0FF,
        200: #E8D4FB,
        300: #D8B9F7,
        400: #C89DF3,
        500: #B881F0,
        600: #A866EC,
        700: #984AE8,
        800: #8842D0,
        900: #7739B9,
        1000: #6731A1,
        1100: #56288A,
        1200: #462072
    ),
    'red': (
        100: #FFEDED,
        200: #FED0D0,
        300: #FCB3B3,
        400: #FB9797,
        500: #FA7A7A,
        600: #F85D5D,
        700: #F74040,
        800: #D93C3C,
        900: #C43333,
        1000: #AB2D2D,
        1100: #912626,
        1200: #782020
    ),
    'orange': (
        100: #FFF2E9,
        200: #FEDFC9,
        300: #FECDAA,
        400: #FEBB8A,
        500: #FDA86A,
        600: #FD964B,
        700: #FC832B,
        800: #E37627,
        900: #CA6923,
        1000: #B25D1F,
        1100: #99501B,
        1200: #804317
    ),
    'yellow': (
        100: #FFF7EE,
        200: #FFECD1,
        300: #FFE0B3,
        400: #FFD596,
        500: #FFCA79,
        600: #FFBE5B,
        700: #FFB33E,
        800: #E6A139,
        900: #CD8E33,
        1000: #B47C2E,
        1100: #9B6928,
        1200: #825723
    ),
    'lime': (
        100: #F8F9E5,
        200: #F1F4CB,
        300: #EDF2B3,
        400: #E8F094,
        500: #E3EE74,
        600: #DAEA3D,
        700: #D0E500,
        800: #BBCE00,
        900: #7D8900,
        1000: #687200,
        1100: #465A00,
        1200: #254200
    ),
    'teal': (
        100: #ECFCFF,
        200: #CDF5FB,
        300: #AFEDF7,
        400: #92E6F3,
        500: #75DFEE,
        600: #57D7EA,
        700: #3AD0E6,
        800: #30B8CB,
        900: #25A1B0,
        1000: #1B8996,
        1100: #10727B,
        1200: #065A60
    ),
    'pink': (
        100: #FFEFF7,
        200: #FFD7EB,
        300: #FFC0DE,
        400: #FFA9D2,
        500: #FF91C6,
        600: #FF7AB9,
        700: #FF62AD,
        800: #E05698,
        900: #C14A83,
        1000: #A23F6E,
        1100: #833359,
        1200: #642744
    ),
    'pro': (
        100: #E8FFFC,
        200: #BEF5EE,
        300: #99EBE0,
        400: #73E1D3,
        500: #4DD6C5,
        600: #28CCB7,
        700: #02C2A9,
        800: #02B09A,
        900: #029F8B,
        1000: #028D7B,
        1100: #027C6C,
        1200: #026A5D
    ),
    'business': (
        100: #F6F6FF,
        200: #E4E3FF,
        300: #B1ABFF,
        400: #B1ABFF,
        500: #B1ABFF,
        600: #584AFF,
        700: #584AFF,
        800: #2E25AD,
        900: #2E25AD,
        1000: #2E25AD,
        1100: #1E1692,
        1200: #0D084D
    ),
    'primary': (
        100: #E8FAF4,
        200: #C9F4E0,
        300: #A6EACA,
        400: #84DFB5,
        500: #62D49F,
        600: #3FCA89,
        700: #1DBF73,
        800: #19A463,
        900: #188652,
        1000: #126F43,
        1100: #0F5533,
        1200: #0B3A23
    )
);
$color_primarys: (
    100: #E8FAF4,
    200: #C9F4E0,
    300: #A6EACA,
    400: #84DFB5,
    500: #62D49F,
    600: #3FCA89,
    700: #1DBF73,
    800: #19A463,
    900: #188652,
    1000: #126F43,
    1100: #0F5533,
    1200: #0B3A23
);
$color_primary_1200: #0B3A23;
$color_primary_1100: #0F5533;
$color_primary_1000: #126F43;
$color_primary_900: #188652;
$color_primary_800: #19A463;
$color_primary_700: #1DBF73;
$color_primary_600: #3FCA89;
$color_primary_500: #62D49F;
$color_primary_400: #84DFB5;
$color_primary_300: #A6EACA;
$color_primary_200: #C9F4E0;
$color_primary_100: #E8FAF4;
$color_businesses: (
    100: #F6F6FF,
    200: #E4E3FF,
    300: #B1ABFF,
    400: #B1ABFF,
    500: #B1ABFF,
    600: #584AFF,
    700: #584AFF,
    800: #2E25AD,
    900: #2E25AD,
    1000: #2E25AD,
    1100: #1E1692,
    1200: #0D084D
);
$color_business_1200: #0D084D;
$color_business_1100: #1E1692;
$color_business_1000: #2E25AD;
$color_business_900: #2E25AD;
$color_business_800: #2E25AD;
$color_business_700: #584AFF;
$color_business_600: #584AFF;
$color_business_500: #B1ABFF;
$color_business_400: #B1ABFF;
$color_business_300: #B1ABFF;
$color_business_200: #E4E3FF;
$color_business_100: #F6F6FF;
$color_pros: (
    100: #E8FFFC,
    200: #BEF5EE,
    300: #99EBE0,
    400: #73E1D3,
    500: #4DD6C5,
    600: #28CCB7,
    700: #02C2A9,
    800: #02B09A,
    900: #029F8B,
    1000: #028D7B,
    1100: #027C6C,
    1200: #026A5D
);
$color_pro_1200: #026A5D;
$color_pro_1100: #027C6C;
$color_pro_1000: #028D7B;
$color_pro_900: #029F8B;
$color_pro_800: #02B09A;
$color_pro_700: #02C2A9;
$color_pro_600: #28CCB7;
$color_pro_500: #4DD6C5;
$color_pro_400: #73E1D3;
$color_pro_300: #99EBE0;
$color_pro_200: #BEF5EE;
$color_pro_100: #E8FFFC;
$color_pinks: (
    100: #FFEFF7,
    200: #FFD7EB,
    300: #FFC0DE,
    400: #FFA9D2,
    500: #FF91C6,
    600: #FF7AB9,
    700: #FF62AD,
    800: #E05698,
    900: #C14A83,
    1000: #A23F6E,
    1100: #833359,
    1200: #642744
);
$color_pink_1200: #642744;
$color_pink_1100: #833359;
$color_pink_1000: #A23F6E;
$color_pink_900: #C14A83;
$color_pink_800: #E05698;
$color_pink_700: #FF62AD;
$color_pink_600: #FF7AB9;
$color_pink_500: #FF91C6;
$color_pink_400: #FFA9D2;
$color_pink_300: #FFC0DE;
$color_pink_200: #FFD7EB;
$color_pink_100: #FFEFF7;
$color_teals: (
    100: #ECFCFF,
    200: #CDF5FB,
    300: #AFEDF7,
    400: #92E6F3,
    500: #75DFEE,
    600: #57D7EA,
    700: #3AD0E6,
    800: #30B8CB,
    900: #25A1B0,
    1000: #1B8996,
    1100: #10727B,
    1200: #065A60
);
$color_teal_1200: #065A60;
$color_teal_1100: #10727B;
$color_teal_1000: #1B8996;
$color_teal_900: #25A1B0;
$color_teal_800: #30B8CB;
$color_teal_700: #3AD0E6;
$color_teal_600: #57D7EA;
$color_teal_500: #75DFEE;
$color_teal_400: #92E6F3;
$color_teal_300: #AFEDF7;
$color_teal_200: #CDF5FB;
$color_teal_100: #ECFCFF;
$color_limes: (
    100: #F8F9E5,
    200: #F1F4CB,
    300: #EDF2B3,
    400: #E8F094,
    500: #E3EE74,
    600: #DAEA3D,
    700: #D0E500,
    800: #BBCE00,
    900: #7D8900,
    1000: #687200,
    1100: #465A00,
    1200: #254200
);
$color_lime_1200: #254200;
$color_lime_1100: #465A00;
$color_lime_1000: #687200;
$color_lime_900: #7D8900;
$color_lime_800: #BBCE00;
$color_lime_700: #D0E500;
$color_lime_600: #DAEA3D;
$color_lime_500: #E3EE74;
$color_lime_400: #E8F094;
$color_lime_300: #EDF2B3;
$color_lime_200: #F1F4CB;
$color_lime_100: #F8F9E5;
$color_yellows: (
    100: #FFF7EE,
    200: #FFECD1,
    300: #FFE0B3,
    400: #FFD596,
    500: #FFCA79,
    600: #FFBE5B,
    700: #FFB33E,
    800: #E6A139,
    900: #CD8E33,
    1000: #B47C2E,
    1100: #9B6928,
    1200: #825723
);
$color_yellow_1200: #825723;
$color_yellow_1100: #9B6928;
$color_yellow_1000: #B47C2E;
$color_yellow_900: #CD8E33;
$color_yellow_800: #E6A139;
$color_yellow_700: #FFB33E;
$color_yellow_600: #FFBE5B;
$color_yellow_500: #FFCA79;
$color_yellow_400: #FFD596;
$color_yellow_300: #FFE0B3;
$color_yellow_200: #FFECD1;
$color_yellow_100: #FFF7EE;
$color_oranges: (
    100: #FFF2E9,
    200: #FEDFC9,
    300: #FECDAA,
    400: #FEBB8A,
    500: #FDA86A,
    600: #FD964B,
    700: #FC832B,
    800: #E37627,
    900: #CA6923,
    1000: #B25D1F,
    1100: #99501B,
    1200: #804317
);
$color_orange_1200: #804317;
$color_orange_1100: #99501B;
$color_orange_1000: #B25D1F;
$color_orange_900: #CA6923;
$color_orange_800: #E37627;
$color_orange_700: #FC832B;
$color_orange_600: #FD964B;
$color_orange_500: #FDA86A;
$color_orange_400: #FEBB8A;
$color_orange_300: #FECDAA;
$color_orange_200: #FEDFC9;
$color_orange_100: #FFF2E9;
$color_reds: (
    100: #FFEDED,
    200: #FED0D0,
    300: #FCB3B3,
    400: #FB9797,
    500: #FA7A7A,
    600: #F85D5D,
    700: #F74040,
    800: #D93C3C,
    900: #C43333,
    1000: #AB2D2D,
    1100: #912626,
    1200: #782020
);
$color_red_1200: #782020;
$color_red_1100: #912626;
$color_red_1000: #AB2D2D;
$color_red_900: #C43333;
$color_red_800: #D93C3C;
$color_red_700: #F74040;
$color_red_600: #F85D5D;
$color_red_500: #FA7A7A;
$color_red_400: #FB9797;
$color_red_300: #FCB3B3;
$color_red_200: #FED0D0;
$color_red_100: #FFEDED;
$color_purples: (
    100: #F8F0FF,
    200: #E8D4FB,
    300: #D8B9F7,
    400: #C89DF3,
    500: #B881F0,
    600: #A866EC,
    700: #984AE8,
    800: #8842D0,
    900: #7739B9,
    1000: #6731A1,
    1100: #56288A,
    1200: #462072
);
$color_purple_1200: #462072;
$color_purple_1100: #56288A;
$color_purple_1000: #6731A1;
$color_purple_900: #7739B9;
$color_purple_800: #8842D0;
$color_purple_700: #984AE8;
$color_purple_600: #A866EC;
$color_purple_500: #B881F0;
$color_purple_400: #C89DF3;
$color_purple_300: #D8B9F7;
$color_purple_200: #E8D4FB;
$color_purple_100: #F8F0FF;
$color_blues: (
    100: #F0F4FF,
    200: #D4DEFB,
    300: #B9C9F7,
    400: #9DB4F3,
    500: #819EF0,
    600: #6689EC,
    700: #446EE7,
    800: #3F63C8,
    900: #3453A8,
    1000: #284389,
    1100: #1D3369,
    1200: #122349
);
$color_blue_1200: #122349;
$color_blue_1100: #1D3369;
$color_blue_1000: #284389;
$color_blue_900: #3453A8;
$color_blue_800: #3F63C8;
$color_blue_700: #446EE7;
$color_blue_600: #6689EC;
$color_blue_500: #819EF0;
$color_blue_400: #9DB4F3;
$color_blue_300: #B9C9F7;
$color_blue_200: #D4DEFB;
$color_blue_100: #F0F4FF;
$color_greens: (
    100: #E8FAF4,
    200: #C9F4E0,
    300: #A6EACA,
    400: #84DFB5,
    500: #62D49F,
    600: #3FCA89,
    700: #1DBF73,
    800: #19A463,
    900: #188652,
    1000: #126F43,
    1100: #0F5533,
    1200: #0B3A23
);
$color_green_1200: #0B3A23;
$color_green_1100: #0F5533;
$color_green_1000: #126F43;
$color_green_900: #188652;
$color_green_800: #19A463;
$color_green_700: #1DBF73;
$color_green_600: #3FCA89;
$color_green_500: #62D49F;
$color_green_400: #84DFB5;
$color_green_300: #A6EACA;
$color_green_200: #C9F4E0;
$color_green_100: #E8FAF4;
$color_greys: (
    100: #FAFAFA,
    200: #F5F5F5,
    300: #EFEFF0,
    400: #E4E5E7,
    500: #DADBDD,
    600: #C5C6C9,
    700: #B5B6BA,
    800: #95979D,
    900: #74767E,
    1000: #62646A,
    1100: #404145,
    1200: #222325
);
$color_grey_1200: #222325;
$color_grey_1100: #404145;
$color_grey_1000: #62646A;
$color_grey_900: #74767E;
$color_grey_800: #95979D;
$color_grey_700: #B5B6BA;
$color_grey_600: #C5C6C9;
$color_grey_500: #DADBDD;
$color_grey_400: #E4E5E7;
$color_grey_300: #EFEFF0;
$color_grey_200: #F5F5F5;
$color_grey_100: #FAFAFA;

$token_colors: (
    'white': #fff,
    'black': #000,
    'fiverr_green': #1DBF73,
    'body': #62646A,
    'heading': #404145,
    'background': #fff,
    'border': #EFEFF0,
    'link': #1DBF73,
    'validation_error': #C43333,
    'validation_warning': #CA6923,
    'validation_success': #188652,
    'validation_information': #3453A8,
    'rising_talent': #4F95F8
);
$color_rising_talent: #4F95F8;
$color_validation_information: #3453A8;
$color_validation_success: #188652;
$color_validation_warning: #CA6923;
$color_validation_error: #C43333;
$color_link: #1DBF73;
$color_border: #EFEFF0;
$color_background: #fff;
$color_heading: #404145;
$color_body: #62646A;
$color_fiverr_green: #1DBF73;
$color_black: #000;
$color_white: #fff;

$fiverr_green_light:           #3FCA89 !default;
$fiverr_green_dark:            #19A463 !default;

$bg_header_offwhite:           #F4F4F4 !default;
$bg_header_grey_lightest:      #eeeeee !default;
$bg_header_grey_med_light:     #888888 !default;
$bg_header_grey_med:           #555555 !default;
$bg_header_grey_dark:          #353537 !default;
$bg_header_grey_darkest:       #2c2c2c !default;
$bg_header_charcoal:           #1f1f1f !default;
$bg_flash_notice_orange:       #ff9326 !default;
$bg_header_dark_blue:          #1e242e !default;

$bg_offwhite:                  #fafafa !default;
$bg_grey_lightest:             #eeeeee !default;
$bg_lightest:                  #f7f7f7 !default;
$bg_lighter:                   #f0f0f0 !default;
$bg_light:                     #e3e3e3 !default;
$bg_grey:                      #c2c2c2 !default;
$bg_blue_light:                #d2eaff !default;
$bg_blue_lightest:             #f5fbfc !default;
$bg_blue_dark:                 #2a4661 !default;
$bg_azure:                     #41b4bb !default;
$bg_azure_light:               #8ed4da !default;
$bg_azure_lightest:            #c9eaed !default;
$bg_peach:                     #FFF2D0 !default;
$bg_green_light:               #eaffdf !default;
$bg_green_lightest:            #edfaf0 !default;
$bg_green_dark:                #164a23 !default;
$bg_yellow:                    #fffdd4 !default;
$bg_modal:                     rgba($black, .65);
$bg_pinterest:                 #d30000 !default;
$bg_turquoise_light:           #d6e7e8 !default;
$bg_turquoise_soft:            rgba(65, 180, 187, .05);
$bg_turquoise:                 rgba(65, 180, 187, .9);
$bg_blue_soft:                 #d4efff !default;
$bg_blue_mid:                  #b3e3ff !default;
$bg_white_transparent:         rgba(255, 255, 255, .1) !default;

$link_white:                   #fdfdfd !default;
$link_offwhite:                #e5e5e5 !default;
$link_green:                   #1DBF73 !default;
$link_blue:                    #4A73E8 !default;
$link_grey:                    #666666 !default;
$link_red:                     #D80000 !default;

$stars_gold:                   #ffbf00 !default;

$text_base_offwhite_ultra:     #eeeeee !default;
$text_base_offwhite:           #dddddd !default;
$text_base_lightest:           #c6c6c6 !default;
$text_base_lighter:            #b2b2b2 !default;
$text_base_light:              #999999 !default;
$text_base_medium:             #777777 !default;
$text_base:                    #555555 !default;
$text_base_dark_medium:        #303030 !default;
$text_base_grey_dark:          #222222 !default;
$text_base_dark:               #0e0e0f !default;
$text_brown:                   #671B00 !default;
$text_pink:                    #E21F91 !default;
$text_fuchsia:                 #f00a71 !default;
$text_base_azure_light:        #abdcdf !default;
$text_azure_medium:            #1fd0b6 !default;
$text_base_turquoise:          #41b4bb !default;
$text_base_teal:               #0097a7 !default;
$text_blue:                    #1362d8 !default;
$text_blue_dark:               #005876 !default;

$error_red:                    #D80000 !default;

$status_orange:                #FF9226 !default;
$status_orange_alt:            #ff6913 !default;
$status_orange_med:            #ffdfbf !default;
$status_orange_light:          #fceac5 !default;
$status_orange_lightest:       #fff6d7 !default;
$status_yellow_light:          #feff94 !default;
$status_blue:                  #00668C !default;
$status_blue_alt:              #36a6c2 !default;
$status_blue_light:            #78cdd2 !default;
$status_blue_lightest:         #D5EAFE !default;
$status_blue_grey:             #568498 !default;
$status_green:                 #1DBF73 !default;
$status_red:                   #FF4D4D !default;
$status_red_light:             #FF7272 !default;
$status_red_lighter:           #ffbebe !default;
$status_grey:                  #808080 !default;
$status_purple:                #f21aa5 !default;
$status_white_blue:            #deeff0 !default;
$status_turquoise:             #41b4bb !default;
$status_turquoise_light:       #d6e7e8 !default;

$border_offwhite:              #F4F4F4 !default;
$border_offwhite_ultra:        #eeeeee !default;
$border_grey_lightest:         #e5e5e5 !default;
$border_grey_light:            #dddddd !default;
$border_grey_med:              #cccccc !default;
$border_grey_med_dark:         #b3b3b3 !default;
$border_grey_dark:             #999999 !default;
$border_grey_darker:           #797979 !default;
$border_grey_darkest:          #434345 !default;
$border_darkest:               #1e1e1f !default;
$border_green_light_grey:      #bcd4c2 !default;
$border_green_dark:            #168A53 !default;
$border_peach_dark:            #F6E2AD !default;
$border_fb_blue:               #3b5998 !default;
$border_twitter_blue:          #60a9dd !default;
$border_google_red:            #dd4b39 !default;
$border_linkedin_blue:         #1b86bc !default;
$border_dribbble_pink:         #ea4c89 !default;
$border_orange_light:          #EED895 !default;
$border_blue_dark:             #1E3751 !default;
$border_azure_light:           #74c5ca !default;
$border_azure_dark:            #40aeb8 !default;
$border_azure_lightest:        #a3d4ef !default;

$page_alert_success:           #caf0d3 !default;
$page_alert_error:             #fcc5c5 !default;
$page_alert_success_border:    #9be2ad !default;

$mobile_header_bg: #111111;
$mobile_header_bg_light: #333333;
$mobile_menu_bg: #2E3237;
$mobile_menu_divi_bg: #363B42;
$mobile_menu_border_bottom: #43474C;

$bg_facebook_icon: #3c5a96;
$bg_twitter_icon:  #28c2fc;
$bg_linkedin_icon: #2487ba;

$colors_map: $colors;

@function color($color_name, $weight: 700) {
    $color: map.get($colors, $color_name);
    @return map.get($color, $weight);
}

$breakpoints: (
    'xs': '(min-width: ' + $breakpoint_xs + ')',
    'sm': '(min-width: ' + $breakpoint_sm + ')',
    'md': '(min-width: ' + $breakpoint_md + ')',
    'lg': '(min-width: ' + $breakpoint_lg + ')',
    'xl': '(min-width: ' + $breakpoint_xl + ')'
);

$breakpoints_reverse: (
    'xs': '(max-width: ' + $breakpoint_reverse_xs + ')',
    'sm': '(max-width: ' + $breakpoint_reverse_sm + ')',
    'md': '(max-width: ' + $breakpoint_reverse_md + ')',
    'lg': '(max-width: ' + $breakpoint_reverse_lg + ')',
    'xl': '(max-width: ' + $breakpoint_reverse_xl + ')'
);

$desktop_min_width: map.get($breakpoints, 'lg');
$tablet_max_width:  map.get($breakpoints_reverse, 'lg');
$tablet_min_width:  map.get($breakpoints, 'sm');
$mobile_max_width:  map.get($breakpoints_reverse, 'sm');

@mixin media-query-grid($media_query) {
    @each $breakpoint in $breakpoints {
        $name: list.nth($breakpoint, 1);
        $declaration: list.nth($breakpoint, 2);

        @if $media_query == $name and $declaration {
            @media only screen and #{$declaration} {
                @content;
            }
        }
    }
}

@mixin media-query-grid-reverse($media_query) {
    @each $breakpoint in $breakpoints_reverse {
        $name: list.nth($breakpoint, 1);
        $declaration: list.nth($breakpoint, 2);

        @if $media_query == $name and $declaration {
            @media only screen and #{$declaration} {
                @content;
            }
        }
    }
}

@mixin custom-media-query($min_viewport: nil, $max_viewport: nil) {
    @if ($min_viewport != nil and $max_viewport != nil) {
        @media (min-width: $min_viewport) and (max-width: $max_viewport) {
            @content;
        }
    } @else if $min_viewport != nil {
        @media (min-width: $min_viewport) {
            @content;
        }
    } @else {
        @media (max-width: $max_viewport) {
            @content;
        }
    }
}

@mixin desktop-tablet {
    @media #{$tablet_min_width} {
        @content;
    }
}

@mixin desktop {
    @media #{$desktop_min_width} {
        @content;
    }
}

@mixin tablet {
    @media #{$tablet_min_width} and #{$tablet_max_width} {
        @content;
    }
}

@mixin tablet-mobile {
    @media #{$tablet_max_width} {
        @content;
    }
}

@mixin mobile {
    @media #{$mobile_max_width} {
        @content;
    }
}

.container {
    margin-bottom: 10px;
}

.selected-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 8px;

    > a {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        background-color: color($grey, 300);
        margin-right: 8px;
        padding: 4px 12px;
        font-weight: 600;
        color: color($grey, 1000);
        border: none;
        text-decoration: none;
        cursor: pointer;
        border-radius: 56px;

        &:hover {
            color: color($grey, 1200);
        }
    }
}
