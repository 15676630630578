.general-video-modal {
    .modal-content {
        background-color: transparent;
        position: relative;

        @include media-query-grid(sm) {
            width: 70vw;
        }

        @include media-query-grid(md) {
            max-width: 900px;
        }

        .modal-content-header {
            border-bottom: 0;
            height: 39px;

            span {
                font-size: 30px;
            }

            .modal-content-close {
                position: relative;
                margin-left: 5px;
                color: $white;
            }
        }

        .modal-content-body,
        .modal-content-header {
            padding: 0;
            background-color: transparent;
        }
    }
}
