@mixin tooltip-custom-design {
    width: 250px;
    line-height: 16px;
    padding: 8px;
    font-weight: 600;
    font-size: 12px;
    background: color($grey, 1200) !important;
}

@mixin tooltip-carrot-position {
    .top-left,
    .bottom-left {
        &::after {
            left: 20% !important;
        }
    }

    .top-right,
    .bottom-right {
        &::after {
            left: 80% !important;
        }
    }
}

@mixin n-rows-ellipsis($rows: 2) {
    @supports (-webkit-line-clamp: 2) {
        text-overflow: ellipsis;
        overflow: hidden;

        // The disable rule here is critical using "box" instead will break text alignment
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        -webkit-line-clamp: $rows;
        -webkit-box-orient: vertical;
    }
}
