$black_03: rgba(0, 0, 0, 0.3);
$black_0: rgba(0, 0, 0, 0);

@mixin highlight-slide-indicator-on-white-background {
    &::after {
        background-image: linear-gradient(to top, $black_03, $black_0);
        bottom: 0;
        content: '';
        height: 40px;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 0;
    }
}
